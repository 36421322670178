<template>
  <div>
    <div class="screen">
    <div class="first" ref="first">
        <div>
            <div class="text">
                <div class="title">{{ t.name }}</div>
                <div class="desc" v-html="t.desc"></div>
                <div class="remark">{{ t.remark }}</div>
            </div>
            <div class="tipImg">
                <img :src="t.pic" alt="" />
            </div>
        </div>
    </div>
      <div class="second" ref="second">
        <div class="tipImg" ref="tipImg">
            <img :src="t.pic2" alt="" />
        </div>
      </div>
      <div class="third" v-if="t.structure.length > 0">
        <div>
          <div class="item" v-for="(it, index) in t.structure" :key="index">
            <div class="label">{{ it.label }}</div>
            <div class="value">{{ it.value }}</div>
          </div>
        </div>
      </div>
      <div class="fourth">
        <div>
          <div class="title">功能介绍</div>
          <el-row>
            <el-col
              :xs="12"
              :sm="8"
              :md="6"
              :lg="6"
              class="module"
              v-for="(it, index) in t.module"
              :key="index"
            >
              <img :src="it.logo" alt="" />
              <div class="title">{{ it.title }}</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="fifth">
        <div>
          <div class="title">技术参数</div>
          <el-row class="content">
            <el-col
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="12"
              v-for="(it, index) in t.params"
              :key="index"
            >
              <p class="label">{{ it.label }}</p>
              <p class="value" v-html="it.value"></p>
            </el-col>
          </el-row>
          <div class="title">产品尺寸</div>
          <img :src="t.plan" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["t"],
  data() {
      return {

      }
  },
  mounted() {

  },
  beforeDestroy() {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.tipImg{
    width: 90vw;
    max-width: 1520px;
    padding-top: 41.4%;
    margin: 0 auto;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
    }
}

.screen {
  width: 100vw;
  line-height: 1.2;
  position: relative;
}
.first {
  width: 100%;
  & > div {
    width: 100%;
    .text {
      max-width: 1120px;
      box-sizing: border-box;
      text-align: center;
      max-width: 1120px;
      margin: 0 auto;
      padding: 12rem 20px 0;
      .title {
        margin-top: 12rem;
        font-size: 3.6rem;
        line-height: 1.2;
        text-align: center;
        letter-spacing: 1px;
        color: #0670d8;
      }
      .desc {
        font-size: 7rem;
        text-align: center;
        letter-spacing: 1.16667px;
        margin-top: 4rem;
        color: #000000;
        font-weight: 200;
      }
      .remark {
        font-size: 2rem;
        line-height: 2;
        text-align: center;
        letter-spacing: 1px;
        color: #000000;
        margin-top: 1rem;
      }
    }
  }
}
.second { 
  padding: 20rem 0;
  background: #000922;
  border: 1px solid#000922;
  box-sizing: border-box;
}
.third {
  background: #000922;
  & > div {
    width: 100%;
    max-width: 1120px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 41rem 20px;
    box-sizing: border-box;
    .item {
      width: 100%;
      margin-bottom: 4rem;
      .label {
        width: 18rem;
        display: block;
        float: left;
        font-size: 2.4rem;

        letter-spacing: 1px;
        color: #ffffff;
      }
      .value {
        width: 100%;
        box-sizing: border-box;
        padding-left: 18rem;
        font-size: 2.4rem;
        letter-spacing: 1px;
        color: #ffffff;
        opacity: 0.7;
      }
    }
  }
}

.fourth {
  background: #000922;
  padding: 21.4rem 0 20rem;
  & > div {
    width: 80%;
    max-width: 1560px;
    margin: 0 auto;
    > .title {
      font-size: 7rem;
      line-height: 1.2;
      letter-spacing: 1px;
      color: #ffffff;
      position: relative;
      font-weight: 200;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: 0;
        width: 3rem;
        height: 0.4rem;
        background-color: #0670d8;
        border-radius: 1px;
      }
    }
    .module {
      padding-top: 12.9rem;
      text-align: center;
      img {
        height: 8rem;
      }
      .title {
        margin-top: 2.7rem;
        font-size: 4rem;
        line-height: 4.7rem;
        letter-spacing: 1px;
        color: #ffffff;
        font-weight: 200;
      }
    }
  }
}
.fifth {
  & > div {
    width: 80%;
    max-width: 1560px;
    margin: 0 auto;
    & > .title {
      padding-top: 20rem;
      padding-bottom: 8rem;
      font-size: 7rem;
      line-height: 1.2;
      letter-spacing: 1px;
      color: #000000;
      font-weight: 200;
    }

    .content {
      font-weight: 200;
      p {
        font-size: 2.2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #000;
        line-height: 1.2;
        letter-spacing: 2px;
        margin-bottom: 4rem;
        vertical-align: top;
      }
      .label {
        width: 15rem;
        float: left;
        color: #696969;
      }
      .value {
        padding-left: 15rem;
        padding-right: 2rem;
        width: 100%;
        box-sizing: border-box;
      }
    }
    & > img {
      width: 100%;
      margin-bottom: 18rem;
    }
  }
}
</style>
